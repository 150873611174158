<template>
  <div class="search section">
    <div class="section-header">
      <div class="section-header--bread">
        <router-link to="/">Головна</router-link>
        <p>|</p>
        <a>Пошук</a>
      </div>
      <div class="input">
        <input class="big" type="text" placeholder="Призвіще" v-model="search" v-on:keyup.enter="submit" />

        <router-link :to="'/search?query=' + search">
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 513.28 513.28"
          >
            <path
              d="M495.04,404.48L410.56,320c15.36-30.72,25.6-66.56,25.6-102.4C436.16,97.28,338.88,0,218.56,0S0.96,97.28,0.96,217.6
			s97.28,217.6,217.6,217.6c35.84,0,71.68-10.24,102.4-25.6l84.48,84.48c25.6,25.6,64,25.6,89.6,0
			C518.08,468.48,518.08,430.08,495.04,404.48z M218.56,384c-92.16,0-166.4-74.24-166.4-166.4S126.4,51.2,218.56,51.2
			s166.4,74.24,166.4,166.4S310.72,384,218.56,384z"
            />
          </svg>
        </router-link>
      </div>
    </div>

    <div class="result section">
      <Loader v-if="load" />

      <div class="result-wrap" v-else>
        <p>За вашим запитом знайдено матеріалів: {{ count }}</p>

        <div class="result-list">
          <div class="items" v-for="(result, index) in results" :key="index">
            <h5>{{ result.cat }}</h5>
            <ul>
              <li v-for="(item, index) in result.data" :key="index">
                <span>номер у черзі</span>{{ item.номер }} <span>П.І.Б.</span>{{ item.прізвище }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  import { useRoute } from "vue-router";

  import lists from "@/store/list.js";
  import { getData } from "../store/dates.js";

  import { ref, reactive, computed, onBeforeMount, onMounted } from "vue";

  import Loader from "../components/global/Loader.vue";

  export default {
    name: "Search",

    components: { Loader },

    setup() {
      const results = reactive([]);
      const count = ref(0);

      const route = useRoute();
      const query = computed(() => route.query.query);
      const search = ref("");
      const load = ref(true);

      onMounted(() => {
        search.value = query.value;
        searchList();
      });

      //Search

      const searchList = () => {
        load.value = true;

        let cnt = 0;

        lists.names.forEach(async (e, index) => {
          try {
            let get = await axios.get(getData(index + 1)),
              header = get.data.values[0],
              rows = get.data.values.slice(1),
              formated = [];

            for (const row of rows) {
              const formattedRow = {};

              header.forEach((e, i) => {
                formattedRow[e.toLowerCase()] = row[i];
              });
              formated.push(formattedRow);
            }

            // console.log(formated);

            let data = formated.filter((e) => e.прізвище.toLowerCase().includes(search.value.toLowerCase()));

            if (data.length > 0) {
              results.push({
                cat: e,
                data,
              });

              count.value += data.length;
            }

            cnt++;
          } catch (e) {
            console.log(e);
          }

          if (cnt == lists.names.length) {
            load.value = false;
          }
        });
      };

      //Keypress

      const submit = () => {
        window.location.href = "/search?query=" + search.value;
      };

      //ScrollTop

      onBeforeMount(() => {
        window.scrollTo();
      });

      return { query, search, results, submit, count, load };
    },
  };
</script>
<style lang="scss">
  .search {
    .result {
      .nothing {
        p {
          color: $mid-grey;
        }

        ul {
          padding-left: 1vw;
          li {
            font-size: 0.8em;
          }
        }
      }

      .result-wrap {
        .result-list {
          margin-top: 32px;
          .items {
            span {
              font-size: 0.8em;
              margin-right: 16px;
              opacity: 0.8;

              &:nth-child(2) {
                margin-left: 16px;
              }
            }
          }
        }
      }
    }
  }
</style>
